import {combineReducers} from 'redux';

import chat from './chat/reducer';
import ticket from './ticket/reducer';
import auth from './auth/reducer';
import sells from './sells/reducer';
import purchases from './purchases/reducer';
import app_notification from './app_notification/reducer';
import search from './search/reducer';

export default combineReducers({
  chat,
  ticket,
  auth,
  sells,
  purchases,
  app_notification,
  search,
});
