import React from 'react';
import {Spinner} from 'react-bootstrap';
import {keyframes} from '@emotion/core';
import styled from '@emotion/styled';

const donutSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Donut = styled.div`
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${(props) =>
    props.color || props.theme.colorsMaketplace.primary};
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  animation: ${donutSpin} 0.6s linear infinite;
`;

export const CenterDonut = styled.div`
  min-height: 150px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-top: 10px;
  }
`;

export default function LoadingCenter({size = 30, color}) {
  return <Donut size={size} color={color} />;
}

export function LoadingCenterHorizontal({size = 30, color}) {
  return (
    <CenterDonut>
      <Donut size={size} color={color} />
    </CenterDonut>
  );
}

export function CenterSpinnerButton({size = 30, color}) {
  return (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  );
}
