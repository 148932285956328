import './fonts.css';
import './icons-guide.css';
import './pincode-input.css';
import React from 'react';
import {Global, css} from '@emotion/core';
import {theme} from '../config';

export default () => (
  <>
    <Global
      styles={css`
        :root {
          --font-family: 'Bradesco Sans', sans-serif;
          position: relative;
        }

        @font-face {
          font-family: 'Bradesco Sans';
          src: url('/fonts/BradescoSans-Regular.woff2') format('woff2');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'Bradesco Sans';
          src: url('/fonts/BradescoSans-Bold.woff2') format('woff2');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Bradesco Sans';
          src: url('/fonts/BradescoSans-SemiBold.woff2') format('woff2');
          font-weight: 600;
          font-style: normal;
        }

        html {
          height: 100%;
          box-sizing: border-box;

          overflow-x: hidden;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        body {
          position: relative;
          min-height: 100%;
          margin: 0;
          background-color: ${theme.colorsCommon.background};
          font-family: var(--font-family);
          color: ${theme.colorsCommon.primary};

          overflow-x: hidden;
        }

        a {
          text-decoration: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
          font-family: var(--font-family);
        }

        p {
          margin: 0;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }

        input,
        textarea {
          border: 0;
          outline: 0;
          display: block;
          width: 100%;
          background-color: #f8f8f8;
          font-family: var(--font-family);
          font-size: 18px;
          color: #454f62;
        }

        button {
          cursor: pointer;
          border: 0;
          outline: 0;
          padding: 0;
          background-color: transparent;
          font-family: var(--font-family);
        }

        .Toastify__toast-container {
          z-index: 999999999999999999;
        }

        .toast-success {
          background: #006634;
        }

        .toast-error {
          background: #ec1313;
        }

        .toast-warn {
          background: #f2c94c;
        }

        .toast-info {
          background: #c4c4c4;
        }
      `}
    />
  </>
);
