import produce from 'immer';

const INITIAL_STATE = {
  chat_open: false,
  conversations: true,
  current_conversation: '',
};

export default function chat(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@chat/CHAT_MODAL': {
        draft.chat_open = action.payload.chat_open;
        break;
      }
      case '@chat/CHAT_CONVERSATION': {
        draft.conversations = action.payload.conversations;
        break;
      }
      case '@chat/CHAT_DATA_CONVERSATION': {
        draft.current_conversation = action.payload.current_conversation;
        break;
      }
      default:
    }
  });
}
