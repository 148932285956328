import React, {useEffect} from 'react';
import HeaderPortal from '../headerPortal';

export default function PagePortal({
  header,
  hiddenHeader,
  hideHeaderAndFooter,
  children,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {!hideHeaderAndFooter && (
        <HeaderPortal hiddenHeader={hiddenHeader} header={header} />
      )}
      {children}
    </>
  );
}
