import logo from '../static/images/logo_bradesco.png';
import logoWhite from '../static/images/logo_bradesco_white.png';
export default {
  menu: {
    search: true,
    home: false,
    ads: false,
    articles: false,
    events: false,
    sell: false,
  },
  hasAuth: false,
  showFooter: true,
  banner_type: "auction",
  appcode: 2,
  config: {
    shouldInvertHeaderColor: true,
  },
  title: 'Bradesco - Vitrine',
  account_name: 'Bradesco',
  logo,
  logoWhite,
  facebookAppId: '',
  googleTrackingId: '',
  googleAppId: '',
  googlePlaceAppId: '-4'
};
