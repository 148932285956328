import produce from 'immer';

const INITIAL_STATE = {
  searchParams: {},
};

export default function searhcParams(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@search/SAVE_SEARCH_PARAMS':
        const {label, value} = action.payload;

        draft.searchParams[label] = value;

        break;

      case '@search/DELETE_SEARCH_PARAM':
        const {label: labelToBeDelete} = action.payload;

        delete draft.searchParams[labelToBeDelete];

        break;

      case '@search/DELETE_ALL_SEARCH_PARAMS':
        const {type} = draft.searchParams;

        draft.searchParams = {};

        draft.searchParams.type = 'all';
        break;

      default:
        break;
    }
  });
}
