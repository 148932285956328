import React from 'react';

import GlobalStyle from './styles/global';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import {ToastContainer, Slide} from 'react-toastify';
import Routes from './routes';
import {createMemoryHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import AppProvider from './providers';

export default function App() {
  const history = createMemoryHistory();

  return (
    <AppProvider>
      <GlobalStyle />

      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Routes />
        </QueryParamProvider>
      </Router>
      <ToastContainer autoClose={3000} draggable transition={Slide} />
    </AppProvider>
  );
}
