import React from 'react';

import './headerPortal.sass';

import {Link} from 'react-router-dom';

import {ContainerHeader, Box, DivWrapper, DivLogo, DivSearch} from './elements';

import Search from '~/components/hallo/search';

import logoBradesco from '~/static/images/bradesco/logo_bradesco_horizontal.svg';

import isMobileDevice from '~/utils/isMobileDevice';

const isIE = /*@cc_on!@*/ false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;

const showBanner = isIE || isEdge;

export default function HeaderPortal({header, hiddenHeader}) {
  return (
    <ContainerHeader hiddenHeader={hiddenHeader}>
      <Box header={header}>
        {showBanner && (
          <div className="ie-banner">
            O seu navegador não é suportado. Por favor utilize o Google Chrome
            ou outro navegador moderno.
          </div>
        )}

        <DivWrapper
          className={isMobileDevice() ? 'header-mobile' : 'header-desktop'}>
          <div>
            <DivLogo>
              <Link to="/">
                <img src={logoBradesco} alt="logo" />
                <img
                  src={logoBradesco}
                  className="mobile-logo"
                  alt="Mobile Logo"
                />
              </Link>
            </DivLogo>
          </div>

          <DivSearch header={header}>
            <Search placeholder="O que você procura?" />
          </DivSearch>
        </DivWrapper>
      </Box>
    </ContainerHeader>
  );
}
