import React, {useState, useEffect} from 'react';

import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import useQuery from '../../../hooks/use-query';
import {IconSearch} from '../icons';

import {Form, ButtonIcon, Input} from './elements';

export default function Search({placeholder, home}) {
  const query = useQuery();

  const [search, setSearch] = useState(query.get('q'));

  const history = useHistory();

  useEffect(() => {
    if (search && !query.get('q')) {
      setSearch('');
    }
  }, [query.get('q')]);

  const {searchParams} = useSelector(({search}) => search);

  function parseUrl() {
    let url = `/auctions?`;

    Object.entries(searchParams).map((query) => {
      const [name, value] = query;

      if (Array.isArray(value)) {
        value.map((param) => {
          url = `${url}${name}=${param}&`;
        });
      } else if (value) {
        url = `${url}${name}=${value}&`;
      }
    });

    if (search) url = `${url}q=${search}`;

    if (!url.includes('type')) url = `${url}&type=all`;

    return url;
  }

  function handleSubmit(event) {
    event.preventDefault();

    const parsedUrl = parseUrl();

    return history.push(parsedUrl);
  }

  function handleSearchChange({target: {value}}) {
    setSearch(value);
  }

  return (
    <Form onSubmit={handleSubmit} home={home}>
      <Input
        value={search}
        home={home}
        onChange={handleSearchChange}
        placeholder={placeholder || 'Buscar imóveis, veículos, equipamentos...'}
      />
      <ButtonIcon type="submit">
        <IconSearch color={home ? '#2A2E43' : '#FAF7F7'} />
      </ButtonIcon>
    </Form>
  );
}
