import React, {lazy, Suspense} from 'react';

import {BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';

import PagePortal from '~/components/portal/pagePortal';
import {LoadingCenterHorizontal} from '~/components/hallo/loading_dialog';

import Route from './Route';

const HomePortal = lazy(() => import('../screens/portal/homePortal'));
const Auctioneers = lazy(() => import('../screens/Auctioneers'));
const AuctionDetail = lazy(() => import('../screens/auctionDetail'));
const Auctioneer = lazy(() => import('../screens/Auctioneer'));
const TermsOfUse = lazy(() => import('../screens/TermsOfUse'));
const Auctions = lazy(() => import('../screens/auctions'));

export default function Routes() {
  return (
    <Router>
      <PagePortal>
        <Suspense
          fallback={
            <div className="container-loading">
              <LoadingCenterHorizontal />
            </div>
          }>
          <Switch>
            <Route exact path="/" component={HomePortal} />

            <Route
              exact
              path="/backoffice"
              component={() => {
                window.location.href = '/backoffice/';
                return null;
              }}
            />
            <Route path="/terms" component={TermsOfUse} />

            <Route path="/auctions/:slug" component={AuctionDetail} />

            <Route path="/auctions" component={Auctions} />

            <Route path="/auctioneer/:guid" component={Auctioneer} />

            <Route path="/auctioneers" component={Auctioneers} />
            
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Suspense>
      </PagePortal>
    </Router>
  );
}
