import produce from 'immer';

const INITIAL_STATE = {
  ad_detail: '',
};

export default function purchases(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@purchases/ADS_DETAIL': {
        draft.ad_detail = action.payload.ad_detail;
        break;
      }
      default:
    }
  });
}
