import variables from './styles/variables.scss';
const {config} = require('./customers');

export const theme = {
  colorsMaketplace: {
    primary: variables.colorsMarketplace_primary,
  },
  colorsUni4life: {
    header: variables.colorsUni4life_header,
    primary: variables.colorsUni4life_primary,
    primaryOpacity078: variables.colorsUni4life_primaryOpacity078,
    primaryOpacity050: variables.colorsUni4life_primaryOpacity050,
    primaryOpacity010: variables.colorsUni4life_primaryOpacity010,
    rectangleArticlePopular: variables.colorsUni4life_rectangleArticlePopular,
    bubbleEvents: variables.colorsUni4life_bubbleEvents,
  },
  colorsCommon: {
    primary: variables.colorsCommon_primary,
    secundary: variables.colorsCommon_secundary,
    tertiary: variables.colorsCommon_tertiary,
    textTitle: variables.colorsCommon_textTitle,
    background: variables.colorsCommon_background,
    placeholder: variables.colorsCommon_placeholder,
  },
  configs: {
    hideHeaderShadow: variables.hideHeaderShadow,
  }
};

export const definitions = config;

// export const uni4life = true;
