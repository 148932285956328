import css from '@emotion/css';
import styled from '@emotion/styled';

export const ContainerHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 999999;

  ${({hiddenHeader}) =>
    hiddenHeader &&
    css`
      display: none;
    `}
`;

export const Box = styled.header`
  display: grid;
  place-items: center;

  width: 100%;

  padding: 0 2rem;

  background: linear-gradient(
    90deg,
    rgba(243, 98, 121, 1) 5%,
    rgba(204, 9, 47, 1) 50%,
    rgba(180, 26, 131, 1) 95%
  );

  max-height: 65px;
`;

export const DivWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1600px;

  height: 100%;

  padding: 15px 0;
`;

export const DivLogo = styled.div`
  flex-shrink: 0;
  margin-right: 25px;

  img {
    height: 30px;
  }

  img.mobile-logo {
    display: none;
  }

  @media only screen and (max-width: 575px) {
    img {
      display: none;

      &.mobile-logo {
        display: block;
        width: 95px;
        margin-left: 5px;
        margin-right: 10px;
      }
    }
  }

  @media screen and (max-width: 800px) {
    margin-right: 0px;
  }
`;

export const DivSearch = styled.div`
  @media screen and (max-width: 500px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (max-width: 575px) {
    width: auto;
    padding-right: 20px;

    form {
      width: 187px;
    }
  }

  form {
    max-width: 290px;
  }
`;
