import {css} from '@emotion/core';
import styled from '@emotion/styled';

export const Form = styled.form`
  position: relative;

  width: 100%;
  height: 35px;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #eaeaea;

  ${({home}) =>
    home &&
    css`
      background: #ffff;

      border-radius: 10px;

      input {
        padding: 0px 42px 0px 40px;

        color: #2a2e43;

        &::placeholder {
          color: #98979c;
          font-weight: 300;
        }
      }
    `}
`;

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 10%;
`;

export const Input = styled.input`
  width: 90%;
  height: 100%;
  background-color: transparent;
  font-size: 14px;
  color: #fafafa;

  &::placeholder {
    color: ${({home}) => (home ? '#2a2e43' : '#fafafa')};
  }
`;

export const TooltipInput = styled.div`
  position: absolute;

  background: #e5173f;

  bottom: -100%;
  left: 12%;

  border-radius: 8px;
  padding: 2px 10px;

  visibility: hidden;

  opacity: 0;

  @keyframes showAnimation {
    from {
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }

  ${(props) =>
    props.show &&
    css`
      animation: showAnimation 250ms forwards;
    `}

  > span {
    font-size: 14px;
    color: #fafafa;
  }

  &::before {
    content: '';
    border-style: solid;
    border-color: #e5173f transparent;
    border-width: 0px 6px 6px 6px;
    top: -19%;
    position: absolute;
    left: 50%;
    transform: translateX(-70px);
  }
`;
