import 'react-app-polyfill/ie9';
import 'weakmap-polyfill';
import 'intl-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import App from './app';
import {definitions} from './config';

import reactGaOptions from '~/config/react-ga';

document.title = definitions.title;

ReactGA.initialize(reactGaOptions.trackingId, {
  alwaysSendToDefaultTracker: true,
});

ReactDOM.render(<App />, document.getElementById('root'));
