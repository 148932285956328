export default function isMobileDevice() {
  const REGEX_DEVICE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;

  if (navigator.userAgent.match(REGEX_DEVICE) !== null) return true;

  return false;
}

export function getMobileOS() {
  const ua = navigator.userAgent;

  let userAgent = null;

  if (/android/i.test(ua)) {
    userAgent = 'Android';
  } else if (/iPad|iPhone|iPod/.test(ua)) {
    userAgent = 'iOS';
  }

  return userAgent;
}
