import {createStore} from 'redux';
import rootReducer from './modules/rootReducer';
import persistReducers from './persistReducers';
import {persistStore} from 'redux-persist';

const store = createStore(
  persistReducers(rootReducer),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const persistor = persistStore(store);

export {store, persistor};
