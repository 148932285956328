import produce from 'immer';
import moment from 'moment';

const INITIAL_STATE = {
  display: true,
  first_date: '',
};

export default function app_notification(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@app_notification/USER_DATA': {
        if (state.display && !action.payload.display) {
          draft.display = action.payload.display;
          draft.first_date = action.payload.date;
          return;
        } else if (!state.display) {
          let first_view = moment(state.first_date);
          let second_view = moment(action.payload.date);

          if (second_view.diff(first_view, 'days') >= 3 && !state.download) {
            draft.display = true;
            draft.first_date = '';
            return;
          }
        }
        break;
      }
      default:
    }
  });
}
