import produce from 'immer';

const INITIAL_STATE = {
  user_info: '',
  ticket_detail: '',
};

export default function ticket(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@ticket/TICKET_DETAIL': {
        action.payload.ticket_detail.infos.forEach((info) => {
          if (action.payload.guid === info.guid) {
            return (draft.user_info = info);
          }
        });
        draft.ticket_detail = action.payload.ticket_detail;
        break;
      }
      default:
    }
  });
}
